export const SetPvAndUv = {
  data() {
    return {
      Page_duration: 0,
      t0: 0, //页面启动到页面响应完成的时间
      t2: 0, //页面启动到加载完成的时间
    }
  },
  beforeCreate() {
    this.Page_duration = new Date().getSeconds()
    this.t0 = new Date().getSeconds()
    this.t2 = new Date().getSeconds()
  },
  beforeDestroy() {
    this.Page_duration = new Date().getSeconds() - this.Page_duration
    //新版埋点 对接微信小程序时使用
    this.setNewZwAplus()
  },
  created() {
    this.t0 = new Date().getSeconds() - this.t0
  },
  mounted() {
    console.log("埋点数据：" + this.$store.state.token)
    this.t2 = new Date().getSeconds() - this.t2
    //浙里办埋点
    this.SetZwAplus()
    //新版埋点 对接微信小程序时使用
    this.setNewZwAplus()
  },
  methods: {
    //新版埋点
    setNewZwAplus() {
      var that = this
      // 在声明 Zwlog 对象实例时，可以传入一些 app 或者用户信息
      const zwlog = new ZwLog({
        _user_id: this.$store.state.zlbUserId,
        _user_nick: this.$store.state.userName,
      })
      //onReady 表示 zwlog 加载完成后的函数，它接收一个匿名函数，而 sendPV 与 record 方法均要在匿名函数内调用。eg:
      zwlog.onReady(function () {
        zwlog.sendPV({
          miniAppId: "2002207363",
          Page_duration: that.Page_duration,
          t2: that.t2,
          t0: that.t0,
          log_status: "2002207363",
        })
        let { metaInfo } = zwlog // SDK 元配置的当前设置
      })
    },
    //浙里办旧版埋点
    SetZwAplus() {
      ZWJSBridge.getLocation()
        .then((result) => {
          if (this.$store.state.token) {
            this.SetZwUserAplus(result.longitude, result.latitude)
          } else {
            this.SetZwaplus(result.longitude, result.latitude)
          }
        })
        .catch((error) => {
          if (this.$store.state.token) {
            this.SetZwUserAplus("", "")
          } else {
            this.SetZwaplus("", "")
          }
          console.log(error)
        })
    },
    // 设置用户信息埋点
    SetZwUserAplus(log, lat) {
      ;(function (w, d, s, q, i) {
        w[q] = w[q] || []
        var f = d.getElementsByTagName(s)[0]
        var j = d.createElement(s)
        j.async = true
        j.id = "beacon-aplus"
        j.src = "https://d.alicdn.com/alilog/mlog/aplus.js?id=202951085"
        f.parentNode.insertBefore(j, f)
      })(window, document, "script", "aplus_queue")
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-rhost-v", "alog.zjzwfw.gov.cn"],
      })
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-rhost-g", "alog.zjzwfw.gov.cn"],
      })
      // 这个会落到 app_key 字段上
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["appId", "60506758"],
      })
      // 单页应用 或 “单个页面”需异步补充 PV 日志参数还需进行如下埋点：
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-waiting", "MAN"],
      })
      var username = ""
      if (this.$store.state.userName) {
        username = this.$store.state.userName
      } else {
        username = this.$store.state.userInfo.username
      }
      console.log("昵称" + username)
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_nick", username], //浙里办的用户名称
      })
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_id", this.$store.state.zlbUserId], //浙里办的用户ID
      })

      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          {
            is_auto: false,
          },
          {
            miniAppId: "2002207363", //IRS平台
            miniAppName: "浙影通", //IRS平台应用标题
            long: log,
            lati: lat,
            userType: this.$store.state.zlbUserType,
          },
        ],
      })
    },
    // 基础信息埋点配置
    SetZwaplus(log, lat) {
      ;(function (w, d, s, q, i) {
        w[q] = w[q] || []
        var f = d.getElementsByTagName(s)[0]
        var j = d.createElement(s)
        j.async = true
        j.id = "beacon-aplus"
        j.src = "https://d.alicdn.com/alilog/mlog/aplus.js?id=202951085"
        f.parentNode.insertBefore(j, f)
      })(window, document, "script", "aplus_queue")
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-rhost-v", "alog.zjzwfw.gov.cn"],
      })
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-rhost-g", "alog.zjzwfw.gov.cn"],
      })
      // 这个会落到 app_key 字段上
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["appId", "60506758"],
      })
      // 单页应用 或 “单个页面”需异步补充 PV 日志参数还需进行如下埋点：
      aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["aplus-waiting", "MAN"],
      })
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          {
            is_auto: false,
          },
          {
            miniAppId: "2002207363", //IRS平台
            miniAppName: "浙影通", //IRS平台应用标题
            long: log,
            lati: lat,
            userType: this.$store.state.zlbUserType,
          },
        ],
      })
    },
  },
}
